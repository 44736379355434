export const nav = [
  {
    name: "Home",
    path: "#home",
  },
  {
    name: "About",
    path: "#about",
  },


  {
    name: "Tokenomics",
    path: "#tokenomics",
  },
  // {
  //   name: "FAQs",
  //   path: "#faq",
  // },
];

export const team = [
  {
    name: "Lucian Potlog",
    position: "Founder",
    desc: "A visually talented MF. The man who first drew the concept of these exceptional Shutter Bots along with Artmarketeer. He is an amazing artist who adapted his talents to the digital age by being a professional graphic designer for over 10 years. Even as a young child he would draw on walls, but now he draws inspiration from the walls as he changed profession pursuing photography full time. He is one of Ireland’s best known street photographers with a following of 20k across his channels.",
    link: "https://linktr.ee/lucian.photography",
  },

  {
    name: "Claudiu Potlog",
    position: "Artist",
    desc: "As a child he must have looked up to his older brother as he followed his footsteps into the life of an artist. Educated in the traditional sense he has a master’s in graphic design. Him and his brother created the art of the NFT collection together probably arguing like brothers do (only because they want the best possible art work) Although he is a master at digital art he still has a huge passion for traditional art with huge canvases across his house that he painted. Most kids played with Lego these two played with pencils.",
    link: "https://linktr.ee/claudesign.art",
  },
  {
    name: "Conor D’Arcy",
    position: "Artist",
    desc: "Visually unstable but visually passionate. A self-taught filmmaker who loves creating stories. Working freelance for over 3 years making the types of adverts that not all brands can use because they are a little unhinged. He has worked with many start- ups always making sure to be as fun as he is professional. A little bit weird but aren’t we all.",
    link: "https://linktr.ee/codarcfilm",
  },
  {
    name: "Karlo Turić",
    position: "Social Media Manager",
    desc: "This man makes Joe exotic look like an accountant. A lover of all exotic creatures and an expert in the NFT space. Karlo is an expert in the NFT space and with social media marketing, amassing over 150k across his channels. He is experienced in design, social media and discord management. Running a successful print on demand business for 3 years now. Actively trading and working in the NFT space. Experienced in design, social media and discord management.",
    link: "https://linktr.ee/exotickarlo",
  },
];

export const roadmap = [
  {
    id: "01",
    points: [
      "$BabyKishu Presale Live",
      "Launch on Uniswap",
      "	CMC & CG listing ",
      "	Get $BabyKishu Trending on twitter with our memetic power",
    ],
    imgUrl: "/images/nft/3.png",
  },
  {
    id: "02",
    points: [
      "	Partnership Campaign",
      "	Partnership Campaign     ",
      "	Viral Time Campaign",
      "	Viral Time Campaign",
      "		CEX Listing ",
    ],
    imgUrl: "/images/nft/4.png",
  },
  {
    id: "03",
    points: [
      "	Scooby Swap",
      "	Scooby Wallet",
      "	Scooby Visa Card",
      "	T1 Exchange Listings     ",
      "	100,000+ holders",
      "	Meme Takeover",
    ],
    // imgUrl: "/images/nft/9.gif",
  },
];

export const FAQ = [
  {
    q: "What is the purpose of Baby Kishu?",
    ans: [
      "Baby Kishu aims to create a community-driven ecosystem where holders can participate in various activities such as staking, yield farming, and accessing upcoming NFT collections. ",
    ],
  },

  {
    q: "What are the benefits of holding Baby Kishu?",
    ans: [
      "Holders of Baby Kishu can potentially benefit from price appreciation as demand for the token grows. Additionally, they may have opportunities to participate in ecosystem activities, earn rewards, and access exclusive NFTs. ",
    ],
  },
  {
    q: "What is the NFT Collection associated with Baby Kishu?",
    ans: [
      "The NFT Collection associated with Baby Kishu refers to a series of non-fungible tokens (NFTs) that will be released in the near future. These NFTs may have unique artwork, features, or utility within the Baby Kishu ecosystem.",
    ],
  },
  {
    q: "How can I participate in the NFT Collection?",
    ans: [
      "Details on how to participate in the NFT Collection will be provided closer to the launch date. This information will likely include instructions on accessing and purchasing the NFTs.",
    ],
  },
  {
    q: "Will there be any utility for Baby Kishu within the NFT ecosystem?",
    ans: [
      "Details about the utility of Baby Kishu within the NFT ecosystem will likely be provided closer to the NFT Collection launch. It's possible that holding Baby Kishu could grant special access or benefits within the NFT platform.",
    ],
  },

];
