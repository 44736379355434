import React, { useEffect } from "react";
import "../scss/banner.scss";
import img1 from "../assests/banner.png";
import heading from "../assests/logo.png";
import i1 from "../assests/i.1.webp";
import i2 from "../assests/i.2.webp";
import i3 from "../assests/i.3.webp";
import i4 from "../assests/i.4.webp";
import i5 from "../assests/i.5.webp";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import ETH from "../assests/scoob/eth.svg";
import USET from "../assests/scoob/usdt.svg";
import logo from "../assests/logo.png"
const Banner = () => {
  useEffect(() => { }, []);
  const copyContractAddress = () => {
    const contractAddress = "0xc2927ed3042e49ec456c20dfd3c48787ee92e33c"; // Your contract address
    navigator.clipboard.writeText(contractAddress)

  };
  return (
    <>
      <section id="banner">
        <div className="container_wrapper">
          <div className="container">
            <div className="content">
              <div className="heading">
                <h1>
                  Baby Kishu
                </h1>
                <div className="contract_btns">
                  <div className="buy_content">
                    <a
                      className="btn"
                      target="_blank"
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0x760cf50b144e723d571c3d8a4e1e7e20e96fed84"
                    >
                      Chart
                    </a>
                    <a
                      className="btn"
                      target="_blank"
                      href="https://app.uniswap.org/#/swap?outputCurrency=0xC2927ED3042e49eC456C20dFd3C48787ee92E33C"
                    >
                      BUY $BabyKishu
                    </a>
                  </div>
                  <div className="contract_address">
                    <p>0xc2927ed3042e49ec456c20dfd3c48787ee92e33c</p>
                    <div className="copy_icon" onClick={copyContractAddress}>
                      <FaCopy />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="end">
              <div className="img">
                <img src={heading} alt="" />
              </div>

            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Banner;
