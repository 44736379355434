import React from "react";
import heading from "../assests/about.h.png";
import img1 from "../assests/Copy of Logo (2).png";
import "../scss/about.scss";
const About = () => {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="content">
            <div className="heading">
              <h1>ABOUT $DIAMOND</h1>
            </div>
            <div className="para">
              <p>
                Lithium is a chemical element denoted by the symbol Li and atomic number 3. It's a silvery-white alkali metal and is known for its low density and high reactivity. Lithium is often used in batteries, particularly in rechargeable lithium-ion batteries, due to its ability to store and release electrical energy efficiently. Additionally, it has applications in psychiatric medicine, as lithium salts are used to treat certain mood disorders like bipolar disorder.
              </p>

            </div>
            <a
              className="btn"
              target="_blank"
              href="#"
            >
              Join Us!
            </a>
          </div>
          <div className="img">
            <img src={img1} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
